<template>
  <!-- v-if="hasPermission('admin_system')" -->

    <div>
      <b-row>

        <b-col md="12" class="3">
          <b-card class="mb-1">
            <b-row>

              <b-col md="3" class="mb-1">
                <v-select v-model="branch_name" v-if="branches_lookups"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'" :options="branches_lookups" label="name"
                  :reduce="val => val.name" placeholder=" All Branches" @input="refresh" />
              </b-col>
              <!-- reset-button -->
              <b-col md="3">
                <b-form-group>
                  <b-form-datepicker v-model="start_date" placeholder="From " dropright reset-button @input="refresh" />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group placeholder=" To">
                  <b-form-datepicker v-model="end_date" placeholder="To " dropright @input="refresh" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

        </b-col>

      </b-row>
  <b-overlay :show="pageLoad" rounded="sm">

      <b-row class="3">


        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between">
              <div>
                <h1 v-if="branches && filter_branch">{{ valid_cases_count.count }}</h1>
                <p> Compliant Cases </p>
              </div>
              <div>
                <b-avatar class="mb-1" variant="success" size="40">
                  <feather-icon size="21" :icon="icon" />
                </b-avatar>
              </div>


            </div>
          </b-card>
        </b-col>

        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between">
              <div>
                <h1 v-if="branches && filter_branch">{{ invalid_cases_count.count }}</h1>
                <p>Non Compliant Cases (Manual & Auto) </p>
              </div>
              <div>
                <b-avatar class="mb-1" variant="danger" size="40">
                  <feather-icon size="21" :icon="icon" />
                </b-avatar>
              </div>


            </div>
          </b-card>
        </b-col>

        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between">
              <div>
                <h1 v-if="branches && filter_branch">{{ total_cases.count }}</h1>
                <p>Total Cases </p>
              </div>
              <div>
                <b-avatar class="mb-1" variant="info" size="40">
                  <feather-icon size="21" :icon="icon" />
                </b-avatar>
              </div>


            </div>
          </b-card>
        </b-col>



      </b-row>
      <b-card>
        <b-row>
          <b-col md="12">
            <h4> Monthly All Cases</h4>
          </b-col>
        </b-row>
        <chartjs-component-bar-chart :options="options2" :height="580" :data="StackedData"
          v-if="branches && filter_branch" />
      </b-card>
      <!-- <b-card>
      <b-row>
           <b-col md="12">
             <h4>   Cumulative All Cases</h4>
           </b-col>     
         </b-row>
         <chartjs-component-bar-chart
         :options="options2"
         :height="480"
         :data="StackedDataCumulative"
         v-if="branches && filter_branch"
       />
     </b-card> -->
      <b-card>
        <b-row>
          <b-col md="12">
            <h4> Monthly Trend (Reviewed Cases)</h4>
          </b-col>
        </b-row>
        <chartjs-component-bar-chart :options="options3" :height="480" :data="MonthlyTrendData"
          v-if="branches && filter_branch" />
      </b-card>
  </b-overlay>

    </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BFormDatepicker, BOverlay,
  BTable,
  BCol, BRow, BImg, BButton, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, BLink
} from 'bootstrap-vue';

import pieChartCategory from '@/views/dashboard/component/pieChart.vue';
import ChartjsComponentBarChart from '@/views/components/charts-components/ChartjsComponentBarChart.vue';
import ChartjsComponentHorizontalBarChart from '@/views/components/charts-components/ChartjsComponentHorizontalBarChart.vue';
import ChartjsComponentLineChart from '@/views/components/charts-components/ChartjsComponentLineChart.vue';

import Statistics from '@/views/components/info/statistics';
import vSelect from 'vue-select';


import { mapGetters } from 'vuex';

import { $themeColors } from '@themeConfig';
// colors
const chartColors = {
  mainColor: '#054978',
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#746D69',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
  second_color: '#f57f21',
  magrabi_default_color: '#771034'
};

export default {
  props: {
    small: Boolean,
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  components: {

    BTable,
    BFormDatepicker,
    vSelect,
    BCard,
    BCol,
    BBadge,
    BLink,
    BImg,
    BRow,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BOverlay,
    Statistics,
    ChartjsComponentBarChart,
    pieChartCategory,
    ChartjsComponentLineChart,
    ChartjsComponentHorizontalBarChart
  },
  data() {
    return {
      start_date: null,
      end_date: null,
      compliance_options: [
        {
          id: 3,
          name: "Compliant Cases (Manual)",
        },
        {
          id: 2,
          name: "Non-Compliant Cases (Manual)",
        },
        {
          id: 0,
          name: "Compliant Cases (Auto)",
        },
        {
          id: 1,
          name: "Non-Compliant Cases (Auto)",
        }

      ],
      compliance: null,

      pageLoad: false,

      filter_branch: true,
      filter: { removed: 0 },
      itemss: [],
      branch_name: null,
      data: null,
      options: {
        backgroundColor: '#771034',
        fill: true,
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
            backgroundColor: '#771034',
            fill: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: true,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Your Title'
            }
          },
          xAxes: [
            {

              scaleLabel: {
                display: true,
                labelString: 'Admission Date'
              },

              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },

              ticks: {
                fontColor: chartColors.labelColor,
                beginAtZero: true,
                stepSize: 1,
              },
            },
          ],
          yAxes: [
            {


              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Admission '
              },
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 100,
                beginAtZero: true,

                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },


      options2: {
        backgroundColor: '#771034',
        fill: true,
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
            backgroundColor: '#771034',
            fill: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: true,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Number Of Cases'
              },

              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },

              ticks: {
                fontColor: chartColors.labelColor,
                beginAtZero: true,
                stepSize: 100,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 100,
                beginAtZero: true,

                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },


      options3: {
        backgroundColor: '#771034',
        fill: true,
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
            backgroundColor: '#771034',
            fill: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: true,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Branches'
              },

              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },

              ticks: {
                fontColor: chartColors.labelColor,
                beginAtZero: true,
                stepSize: 10,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 10,
                beginAtZero: true,

                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },
    };

  },
  computed: {

    newFields() {
      let newFields = [
        {
          key: 'id',
          label: 'Case ID',
          sortable: true,
        },
        {
          key: 'medical_record',
          label: ' MRN',
          sortable: true,
        },
        {
          key: 'status',
          label: 'status',
          sortable: true,
        },

        {
          key: 'branch.name',
          label: 'Branch',
          sortable: true,
        },
        // {
        //   key: 'branch.name',
        //   label: 'update',
        //   sortable: true,
        // },


        {
          key: 'action',
          label: 'Actions',
          sortable: true,
        },





      ];


      return newFields;
    }
    ,
    ...mapGetters({
      counts: 'dashboard/counts',
      faculty: 'dashboard/faculty',
      //   activities: 'dashboard/activities',
      branches: 'dashboard/branches',
      branches_lookups: 'dashboard/branches_lookups',
      valid_cases_count: 'dashboard/valid_cases_count',
      invalid_cases_count: 'dashboard/invalid_cases_count',
      // valid_cases_count_per_month: 'dashboard/valid_cases_count_per_month',
      // invalid_cases_count_per_month:'dashboard/invalid_cases_count_per_month',
      total_cases: 'dashboard/total_cases',
      stacked_cases: 'dashboard/stacked_cases_branch',
      monthly_trend: "dashboard/monthly_trend_branch",
      payment: 'dashboard/payment',
      items: 'cases/items',
      load: 'cases/load',
      total: 'cases/total',
      lookups: 'cases/lookups',

    }),
    MonthlyTrendData() {
      console.log(this.monthly_trend);
      if (this.monthly_trend) {

        return {
          labels: Array.from(this.monthly_trend, (el) => el.label),
          datasets: [
            {
              label: 'Compliant Cases',
              data: Array.from(this.monthly_trend, (el) => el.compliant),
              backgroundColor: "#28c76f",
              borderColor: "transparent",

            },
            {
              label: 'Non Compliant Cases',
              data: Array.from(this.monthly_trend, (el) => el.non_compliant),
              backgroundColor: "#ea5455",
              borderColor: "transparent",

            },
          ],
        };
      }
    },

    StackedData() {
      if (this.stacked_cases) {
        return {
          // labels: ['one', 'two' , 'three' , 'four'],
          labels: Array.from(this.stacked_cases, (el) => el.label),
          datasets: [
            {
              label: "Non Compliant",
              //  data:[1200, 19, 3, 5, 2, 3],
              data: Array.from(this.stacked_cases, (el) => el.non_compliant),

              backgroundColor: "#ea5455",
              borderColor: "transparent",
              borderWidth: 0,
              borderRadius: 20,
              border: 0,

              borderSkipped: false,
            },
            {
              label: "Compliant",

              // data: [1300, 19, 3, 5, 2, 3],
              data: Array.from(this.stacked_cases, (el) => el.compliant),
              backgroundColor: "#28c76f",
              borderColor: "transparent",
              borderWidth: 0,
              borderRadius: 20,
              border: 0,

              borderSkipped: false,
            },


          ],
        };
      }
    },

    StackedDataCumulative() {

      if (this.stacked_cases) {
        const compliant_count = [];
        const non_compliant_count = [];

        this.stacked_cases.forEach((element) => {
          compliant_count.push(element.compliant);
          non_compliant_count.push(element.non_compliant);
        });

        const compliant_cumulative = [];

        const non_compliant_cumulative = [];


        compliant_count.forEach((number, index) => {
          compliant_cumulative.push(number + (compliant_cumulative[compliant_cumulative.length - 1] || 0));
        });

        non_compliant_count.forEach((number, index) => {
          non_compliant_cumulative.push(number + (non_compliant_cumulative[non_compliant_cumulative.length - 1] || 0));
        });
        console.log(non_compliant_cumulative);
        return {
          labels: Array.from(this.stacked_cases, (el) => el.label),
          datasets: [
            {
              label: "Compliant Case",
              data: compliant_cumulative,
              backgroundColor: "#28c76f",
              borderColor: "transparent",
              borderWidth: 0,
              borderRadius: 20,
              border: 0,

              borderSkipped: false,
            },
            {
              label: "Non Compliant Cases",
              data: non_compliant_cumulative,

              backgroundColor: "#ea5455",
              borderColor: "transparent",
              borderWidth: 0,
              borderRadius: 20,
              border: 0,

              borderSkipped: false,
            },




          ],
        };
      }
    },



  },
  methods: {
    getStatistic() {
      this.pageLoad = true;
      //   this.$store.dispatch('dashboard/Activities');
      this.$store.dispatch('dashboard/branches').then(_ => {
         this.pageLoad = false;
         
    })
      this.$store.dispatch('dashboard/branches_lookups');
      // this.$store.dispatch('dashboard/paymentPerMonth');
    },
    refresh(query) {

      this.filter_branch = false;
      console.log(query);
      this.$store.dispatch('dashboard/branches', {
        branch_name: this.branch_name,
        compliance: this.compliance,
        start_date: this.start_date,
        end_date: this.end_date,
      }).then(_ => {

        this.filter_branch = true;


      })



      // this.$store.dispatch('cases/cases', { query });

    },
  },
  mounted() {
    this.getStatistic();
    // this.refresh();
  },
};
</script>
